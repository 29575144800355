// load fonts
WebFont.load({
    google: { // add &display=swap to end of ONLY END font family, not every single one, other it doesnt load those fonts after
        families: ['Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200', 'Space+Grotesk:400,500,600,700&display=swap']
    },
    active: function () { // helps stop flash of unstyled text
        sessionStorage.fontsLoaded = true
    }
});

require('beefup/dist/js/jquery.beefup.min.js');
require('@fancyapps/fancybox/dist/jquery.fancybox.min.js');

require('./modules/core');