jQuery(document).ready(function ($) {
    $(".hamburger").click(function () {
        $(this).toggleClass("is-active");
        $('nav.menu').toggleClass('active');
    });
    $(function () {
        $('a[href*="#"]:not([href="#"])').click(function () {
            if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                if (target.length) {
                    $('html, body').animate({
                        scrollTop: target.offset().top
                    }, 750);
                    return false;
                }
            }
        });
    });


    $(function () {
        $('.beefup').beefup({
            openSingle: true
        });
    });

    $('.hero-banner .slides').slick({
        arrows: false,
        autoplay: true,
        autoplaySpeed: 4000,
        adaptiveHeight: true,
        dots: true,
        infinite: true,
        slidesToShow: 1,
        speed: 300,
    });

    var $animation_elements = $('.animation-element');
    var $window = $(window);

    function check_if_in_view() {
        var window_height = $window.height();
        var window_top_position = $window.scrollTop();
        var window_bottom_position = (window_top_position + window_height);

        $.each($animation_elements, function () {
            var $element = $(this);
            var element_height = $element.outerHeight();
            var element_top_position = $element.offset().top;
            var element_bottom_position = (element_top_position + element_height);

            //check to see if this current container is within viewport
            if ((element_bottom_position >= window_top_position) &&
                (element_top_position <= window_bottom_position)) {
                $element.addClass('in-view');
                $element.removeClass('in-bottom');
            } else {
                $element.removeClass('in-view');
                $element.addClass('in-bottom');
            }

        });
    }
    $window.on('scroll resize', check_if_in_view);
    $window.trigger('scroll');

    $(function () {
        var scroll = $(document).scrollTop();
        var headerHeight = $('header.header').outerHeight();
        $(window).scroll(function () {
            var scrolled = $(document).scrollTop();
            if (scrolled < 200) {
                $('header.header').removeClass('scrolling-down');
                $('.vertical-arrow').removeClass('scrolled');

            } else {

                if (scrolled > scroll) {
                    $('header.header').addClass('scrolling-down');
                    $('.vertical-arrow').addClass('scrolled');
                } else {
                }
            }
            scroll = $(document).scrollTop();
        });
    });

});